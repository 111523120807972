import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from "react"
import { Helmet as Head } from "react-helmet"
import { getCachedSecret, useUser } from "../hooks/use-user"
import { useMedia } from "../hooks/use-media"
import { useStyle } from "../hooks/use-style"
import { lightTheme, darkTheme } from "../services/theme"
import { useNode } from "../hooks/use-node"

const ConfirmPage: FunctionComponent = ({ location }) => {
  const { user } = useUser()
  const { rpc } = useNode()
  const [state, setState] = useState("prompt")

  const css = useStyle(theme => ({
    backgroundColor:
      theme === "light"
        ? lightTheme.color.background.secondary
        : darkTheme.color.background.primary,
    primaryBackgroundColor:
      theme === "light"
        ? lightTheme.color.background.primary
        : darkTheme.color.background.primary,
    inputBackground:
      theme === "light"
        ? lightTheme.color.background.secondary
        : darkTheme.color.background.primary,
    borderColor:
      theme === "light" ? lightTheme.color.border : darkTheme.color.border,
    inputBorderColor:
      theme === "light" ? lightTheme.color.border : "transparent",
    focusColor:
      theme === "light" ? lightTheme.color.active : darkTheme.color.active,
    activeButtonBackgroundColor:
      theme === "light"
        ? lightTheme.color.background.secondary
        : darkTheme.color.background.primary,

    headerBackgroundColor:
      theme === "light"
        ? lightTheme.color.background.primary
        : darkTheme.color.background.secondary,
  }))

  const params =
    location && location.search && location.search.length > 2
      ? new URLSearchParams(location.search)
      : null

  const onConfirm = useCallback(() => {
    if (params && user && user.id) {
      const msg = {
        platform: params.get("platform"),
        id: params.get("id"),
        account: params.get("account"),
        proxy: params.get("proxy"),
        default_amount: parseFloat(params.get("amount")),
        signature: params.get("signature"),
      }

      rpc(
        "add_proxy",
        JSON.stringify(msg),
        user,
        getCachedSecret(),
        function (res) {
          if (res.status == "ok") {
            setState("ok")
          } else {
            setState(res.status)
          }
        }
      )
    }
  }, [user])

  return (
    <>
      <Head>
        <title>Smartlike</title>
        <meta name="description" content="Smartlike p2p donations" />
        <meta property="og:title" content="Smartlike" />
        <meta property="og:description" content="Smartlike p2p donations" />
      </Head>

      <div style={{ maxWidth: 640, margin: "auto" }}>
        <div className="main">
          {state == "prompt" &&
            params &&
            params.has("amount") &&
            params.has("platform") &&
            params.get("platform") == "telegram" &&
            params.has("id") &&
            params.has("name") &&
            params.has("account") &&
            params.has("proxy") &&
            params.has("signature") && (
              <>
                <div className="header">Confirmation</div>
                <div className="message">
                  Would you like to connect telegram account{" "}
                  <b>{params.get("name")}</b> to Smartlike account{" "}
                  <span style={{ whiteSpace: "nowrap" }}>
                    <b>{params.get("account")}</b>
                  </span>{" "}
                  and authorize{" "}
                  <a href="https://t.me/smartlike_org_bot">smartlike_org_bot</a>{" "}
                  operated by{" "}
                  <a href="https://smartlike.org/channel/4855e1d3-ac4a-f6c4-8e03-f66001cef053">
                    Smartlike
                  </a>{" "}
                  to forward donations?
                </div>
                <div>
                  {" "}
                  <button
                    style={{
                      padding: "5px",
                      width: "100px",
                      marginTop: "30px",
                      marginBottom: "10px",
                      fontSize: "16px",
                    }}
                    id="password-init-submit"
                    type="submit"
                    onClick={() => onConfirm()}
                  >
                    Confirm
                  </button>
                </div>
              </>
            )}

          {state != "prompt" && state == "ok" ? (
            <>
              <div className="header">Confirmation</div>
              <div className="message" style={{ textAlign: "center" }}>
                Confirmation sent. Thank you.
              </div>
            </>
          ) : (
            state != "prompt" && (
              <>
                <div className="header">Confirmation</div>
                <div className="message" style={{ textAlign: "center" }}>
                  Failed to sent confirmation: {state}
                </div>
              </>
            )
          )}
        </div>
      </div>

      <style jsx global>{`
        h2 {
          font-weight: 500 !important;
        }
        h3 {
          font-weight: 500 !important;
          /*text-align: center;*/
          font-size: 28px;
        }
        figure {
          margin: 0;
          text-align: center;
        }
        p,
        li {
          line-height: 28px !important;
          font-size: 18px;
          text-underline-offset: 2px;
          /*font-weight: normal;*/
          font-weight: 400;
          /*color: rgba(0, 0, 0, 0.84);*/
        }
        b,
        strong {
          font-weight: 500 !important;
          /*color: rgba(0, 0, 0, 0.84);*/
        }
        body {
          background-color: ${css.primaryBackgroundColor} !important;
        }
      `}</style>

      <style jsx>{`
        .main {
          text-align: center;
          font-family: Roboto;
          font-size: 18px;
        }
        .message {
          text-align: justify;
          font-size: 16px;
          line-height: 24px;
        }
        .header {
          margin-bottom: 20px;
          margin-top: 50px;
          font-size: 20px;
          font-family: Roboto;
          text-align: center;
        }
      `}</style>
    </>
  )
}
export default ConfirmPage
